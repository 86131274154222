<template>
  <div style="height: 100%;background: #ebedf0;">
    <div v-show="!pleshow">
      <div class="biao_ti">
        <van-icon @click="back" class="fanhui" name="arrow-left" />
        <div class="bost tis" @click="totanadd">
          <img class="toimg" src="../../assets/img/toadduser.png" alt />
        </div>
        <div class="bost tis" @click="toshow(1)">
          <div>我推荐的客户</div>
          <div>（{{list.length}}人）</div>
          <div class="heng" v-if="active == 1"></div>
        </div>
        <div class="bost tis" @click="toshow(2)">
          <div>我的团队</div>
          <div>（{{teamlist.length}}人）</div>
          <div class="heng" v-if="active == 2"></div>
        </div>
        <div class="bost tis" @click="toshow(3)">
          <div class="myma">我的团队码</div>
          <div class="dd">&#12288</div>
          <div class="heng" v-if="active == 3"></div>
        </div>
      </div>
      <div class="mian">
        <div class="heds" v-if="active == 1">
          <van-row style="font-size: 13px;">
            <van-col span="2" class="ls">#</van-col>
            <van-col span="6" class="ls">车牌</van-col>
            <van-col span="4" class="rs">车主姓名</van-col>
            <van-col span="6" class="ls">电话号码</van-col>
            <van-col span="6" class="rs">注册时间</van-col>
          </van-row>

          <van-row v-for="(item,idx) in list" :key="idx">
            <van-swipe-cell>
              <van-col span="2" class="ls">{{idx+1}}</van-col>
              <van-col span="6" class="ls" style>
                <span style="vertical-align: middle;">{{item.carNo}}</span>
              </van-col>
              <van-col span="4" class="rs">{{item.customerName}}</van-col>
              <van-col span="6" class="ls">{{item.phone}}</van-col>
              <van-col span="6" class="rs" @click.native="totuijianxq(item)">
                <span style="vertical-align: middle">{{item.createdDate.substring(0,10)}}</span>
                <van-icon style="vertical-align: middle;margin-top: 1px;" name="arrow" />
              </van-col>
              <template #right>
                <van-button
                  square
                  @click="toshanchutuijian(item)"
                  text="删除"
                  type="danger"
                  class="delete-button"
                />
                <van-button
                  square
                  @click="toaddtuijian(item)"
                  text="添加"
                  type="primary "
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>
          </van-row>
        </div>
        <div class="hedst" v-if="active == 2">
          <div class="shijian" @click="show= true">
            <span style="vertical-align: middle;">{{times}}</span>
            <van-icon class="xiaqu" name="play" />
          </div>
          <van-row style="font-size: 13px;">
            <van-col span="2" class="ls">#</van-col>
            <van-col span="6" class="ls">员工姓名</van-col>
            <van-col span="4" class="rs">成员数</van-col>
            <van-col span="4" class="rs">客户数</van-col>
            <van-col span="4" class="ls">月单数</van-col>
            <van-col span="4" class="rs">月收入</van-col>
          </van-row>
          <div class="neimian">
            <van-row v-for="(item,idx) in teamlist" :key="idx">
              <van-swipe-cell>
                <van-col span="2" class="ls">{{idx+1}}</van-col>
                <van-col span="6" class="ls" style>
                  <span style="vertical-align: middle;">{{item.name}}</span>
                </van-col>
                <van-col span="4" class="rs">{{item.memberCounts}}</van-col>
                <van-col span="4" class="rs">{{item.customerCounts}}</van-col>
                <van-col span="4" class="ls">{{item.mouthCounts}}</van-col>
                <van-col span="4" class="rs" @click.native="tomy_perf(item)">
                  <span style="vertical-align: middle">{{item.mouthAmount}}</span>
                  <van-icon style="vertical-align: middle;margin-top: 1px;" name="arrow" />
                </van-col>
                   <van-row style="border-bottom:none;">
                <div span="12" class="divban">
                  <img class="logoimg" src="../../assets/img/dianhualogo.png" alt />
                  <span style="vertical-align: middle;" v-if="item.phone">{{item.phone}}</span>
                  <span v-else style="color: red;">未注册</span>
                </div>
                <div span="12" class="divban">
                  <img class="logoimg" src="../../assets/img/chelogo.png" alt />
                  <span style="vertical-align: middle;" v-if="item.carNo">{{item.carNo}}</span>
                  <span v-else style="color: red;">未注册</span>
                </div>
              </van-row>
                <template #right>
                  <van-button
                    square
                    @click="toshanchu(item)"
                    text="删除"
                    type="danger"
                    class="delete-button"
                  />
                </template>
              </van-swipe-cell>
           
            </van-row>
          </div>
        </div>
        <div class="imgse" v-if="active == 3">
          <div class="ma">
            <vue-qr
              :text="downloadData.url"
              :margin="10"
              colorDark="#000"
              colorLight="#fff"
              :dotScale="1"
              :logoSrc="downloadData.icon"
              :logoScale="0.2"
              :size="220"
            ></vue-qr>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <van-popup v-model="show" position="bottom" :style="{ height: '315px' }">
        <van-datetime-picker
          v-model="currentDate"
          @confirm="toshijian"
          @cancel="show =false"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
        />
      </van-popup>
      <van-dialog v-model="showren" title="添加客户信息" show-cancel-button :before-close="onBeforeClose">
        <div class="tanhes">
          <div class="tanh1">
            <span class="yonghu">微信名称：</span>
            {{renform.customerName}}
          </div>
          <div class="tanh1">
            <span class="yonghu">用户ID：</span>
            <input v-model="mallUserId" type="tel" />
          </div>
          <div class="tanh1">
            <span class="yonghu">车牌号码：</span>
            <input @click="getple" readonly v-model="renform.carNo" type="text" />
          </div>
          <div class="tanh1">
            <span class="yonghu">手机号码：</span>
            <input v-model="renform.phone" type="tel" />
          </div>
        </div>
      </van-dialog>
      <van-dialog
        v-model="showadd"
        title="添加车牌"
        show-cancel-button
        confirmButtonText="添加"
        :before-close="onBeforeCloseadd"
      >
        <div class="tanhes">
          <div class="tanh1">
            <span class="yonghu left">用户ID号：</span>
            <input class="left" v-model="mallUserId" type="tel" />
            <van-button type="danger" class="left chaxun" size="small" @click="toinquire">查询</van-button>
          </div>
          <div class="tanh1">
            <span class="yonghu">微信名称：</span>
            <span style="color: red;">{{nameform.realName}}</span>
          </div>

          <div class="tanh1">
            <span class="yonghu">车牌号码：</span>
            <input @click="getple" readonly v-model="renform.carNo" type="text" />
          </div>
          <div class="tanh1">
            <span class="yonghu">客户姓名：</span>
            <input v-model="renform.name" type="text" />
          </div>
          <div class="tanh1">
            <span class="yonghu">手机号码：</span>
            <input v-model="renform.phone" type="tel" />
          </div>
        </div>
      </van-dialog>
    </div>
    <carPlate ref="ple" v-show="pleshow" :plate_number="renform.carNo" @getPlate="getPlate"></carPlate>
  </div>
</template>

<script>
import {
  tuokelonger,
  sellManteam,
  qrsell,
  sellMandelete,
  deleteoffId,
  findById,
  tokerrecommended,
  savecarInfo
} from "../../api/check";
import vueQr from "vue-qr";
import carPlate from "../../components/carplate.vue";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: { vueQr, carPlate },
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [], //
      pleshow: false,
      showadd: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      teamlist: [],
      downloadData: {
        url: "",
        icon: ""
      },
      show: false,
      showren: false,
      mallUserId: "",
      renform: {},
      nameform: {},
      times: "",
      mouthDate: "", //当前年月
      orderNumber: "",
      active: 1,
      index: 0,
      count: "",
      token: "",
      isok: false
    };
  },
  methods: {
    totanadd() {
      this.renform = {};
      this.nameform = {};
      this.mallUserId = "";
      this.showadd = true;
    },
    //根据id获取信息
    toinquire() {
      // console.log(this.nameform.id);
      if (this.mallUserId == "" || this.mallUserId == undefined) {
        this.$toast("请先输入id号");
        return;
      }
      findById({ id: this.mallUserId }).then(res => {
        if (res.code == 200) {
          if (res.data == undefined) {
            this.$toast("查询失败，请输入正确id号！");
            return;
          }
          this.nameform = res.data;
          this.$toast.success("查询成功！");
        }
      });
    },
    toaddtianjia(item) {
      if (this.nameform.id == "" || this.nameform.id == undefined) {
        this.$toast("请先查询用户id");
        return;
      }
      if (this.renform.carNo == "" || this.renform.carNo == undefined) {
        this.$toast("请先填写客户车牌号码！");
        return;
      }
      if (this.renform.name == "" || this.renform.name == undefined) {
        this.$toast("请先填写客户姓名！");
        return;
      }
      if (this.renform.phone == "" || this.renform.phone == undefined) {
        this.$toast("请先填写客户手机号码！");
        return;
      }
      var data = {
        carNo: this.renform.carNo,
        customerId: this.nameform.id,
        flag: true,
        garageName: this.shangjia.name,
        gid: this.shangjia.id,
        name: this.renform.name,
        phone: this.renform.phone
      };
      tokerrecommended(data).then(res => {
        if (res.code == 200) {
          this.$toast("添加客户成功！");
          this.namelist();
          // this.teamlond();
          this.showleft = false;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    toshanchu(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除团队成员： " + item.name
        })
        .then(() => {
          var data = {
            uid: item.uid,
            gid: item.gid,
            sid: this.userInfo.id
          };
          sellMandelete(data).then(res => {
            if (res.code == 200) {
              this.$toast("删除成功！");
              this.teamlond();
            } else {
              this.$toast(res.data.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    //删除推荐的客户
    toshanchutuijian(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message:
            "是否删除推荐客户： " + item.customerName + ",车牌：" + item.carNo
        })
        .then(() => {
          var data = {
            gid: item.gid,
            offId: item.offId,
            carNo: item.carNo
          };
          deleteoffId(data).then(res => {
            if (res.code == 200) {
              this.$toast("删除成功！");
              this.namelist();
            } else {
              this.$toast(res.data.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toshijian(e) {
      var _this = this;
      this.mouthDate = this.timeFormat(e, 1);
      this.times = this.timeFormat(e, 2);
      _this.teamlond();
      this.show = false;
    },
    timeFormat(time, type) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      var months = month < 10 ? "0" + month : month;
      if (type == 1) {
        return year + "-" + months;
      } else {
        return year + "年" + months + "月";
      }
    },
    toshow(idx) {
      this.active = idx;
      if (idx == 2) {
        this.teamlond();
      } else if (idx == 3) {
        this.malist();
      }
    },
    tomy_perf(item) {
      // this.$router.push({
      //   name: "My_secondTow",
      //   query: {
      //     uid: item.uid,
      //     name: item.name,
      //     gid: item.gid
      //   }
      // });
      this.$router.push({
        name: "My_performance",
        query: {
          uid: item.uid,
          name: item.name,
          isok: "true",
          gid: item.gid
        }
      });
      // this.$router.push({
      //   name: "My_performance",
      //   query: {
      //     uid: item.uid,
      //     name: item.name,
      //     isok: "true"
      //   }
      // });
    },
    //认证
    onBeforeClose(action, done) {
      if (action === "confirm") {
        var _this = this;
        if (_this.mallUserId == "") {
          _this.$toast("请输入用户ID");
          return done(false);
        } else if (_this.mallUserId != _this.renform.offId) {
          _this.$toast("请输入正确的用户ID");
          return done(false);
        }
        // 你的逻辑代码
        if (_this.renform.phone == "" || !_this.renform.phone) {
          _this.$toast("请输入手机号码");

          return done(false);
        } else if (!/^1[3456789]\d{9}$/.test(_this.renform.phone)) {
          _this.$toast("手机号码格式错误");
          return done(false);
        } else if (_this.renform.carNo.length < 6) {
          _this.$toast("请填写车牌号码");
          return done(false);
        }
        const data = {
          carNo: _this.renform.carNo,
          gid: _this.renform.gid,
          mallUserId: _this.mallUserId,
          phone: _this.renform.phone,
          haveChange: 1,
          haveAdopt: 1,
          onDefault: true //是否默认车辆
        };
        savecarInfo(data).then(e => {
          // loading.clear();
          if (e.code == 200) {
            this.namelist();

            this.showren = false;
            this.$toast("添加成功！");
          }
        });
      } else {
        return done();
      }
    },
    //添加车牌
    onBeforeCloseadd(action, done) {
      var _this = this;
      if (action === "confirm") {
        if (_this.nameform.id == "" || _this.nameform.id == undefined) {
          _this.$toast("请先查询用户id");
          return done(false);
        }
        if (_this.renform.carNo == "" || _this.renform.carNo == undefined) {
          _this.$toast("请先填写客户车牌号码！");
          return done(false);
        }
        if (_this.renform.name == "" || _this.renform.name == undefined) {
          _this.$toast("请先填写客户姓名！");
          return done(false);
        }
        if (_this.renform.phone == "" || _this.renform.phone == undefined) {
          _this.$toast("请先填写客户手机号码！");
          return done(false);
        } else if (!/^1[23456789]\d{9}$/.test(_this.renform.phone)) {
          _this.$toast("手机号码格式错误");
          return done(false);
        }
        var data = {
          carNo: _this.renform.carNo,
          customerId: _this.nameform.id,
          flag: true,
          garageName: _this.shangjia.name,
          gid: _this.shangjia.id,
          name: _this.renform.name,
          phone: _this.renform.phone
        };
        tokerrecommended(data).then(res => {
          if (res.code == 200) {
            _this.$toast("添加客户成功！");
            _this.namelist();
            // this.teamlond();
            _this.showleft = false;
          } else {
            _this.$toast(res.data.message);
          }
        });
      } else {
        return done();
      }
    },
    //打开键盘
    getple() {
      this.pleshow = true;
      this.$refs.ple.clickShowKeyboard();
    },
    getPlate(val) {
      console.log(val);
      this.renform.carNo = val;
      this.pleshow = false;
    },
    toaddtuijian(item) {
      this.renform = {};
      this.renform = JSON.parse(JSON.stringify(item));
      this.mallUserId = item.offId;
      this.renform.carNo = "";
      this.showren = true;
    },
    totuijianxq(item) {
      console.log(item);
      if (item.createdDate == "待注册") {
        this.renform = JSON.parse(JSON.stringify(item));
        this.mallUserId = "";
        this.showren = true;
      } else {
        this.$router.push({
          name: "indextwo",
          query: {
            customerId: item.offId,
            carNo: item.carNo,
            customerName: item.customerName,
            phone: item.phone,
            gid: item.gid,
            showisok: "消费",
            gidisok: true
          }
        });
      }
    },
    teamlond() {
      sellManteam({ uid: this.userInfo.id, mouthDate: this.mouthDate }).then(
        res => {
          if (res.code == 200) {
            this.teamlist = res.data;
          } else {
            this.$toast(res.data.message);
          }
        }
      );
    },
    namelist() {
      //
      // this.userInfo.id
      tuokelonger({ customerId: this.userInfo.id }).then(res => {
        if (res.code == 200) {
          this.list = res.data;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    addDate() {
      var nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };

      var month = date.month < 10 ? "0" + date.month : date.month;
      var dates = date.date < 10 ? "0" + date.date : date.date;
      // let systemDate = date.year + "-" + 0 + date.month + "-" + 0 + date.date;
      let systemDate = date.year + "-" + month;
      this.mouthDate = systemDate;
      this.times = date.year + "年" + month + "月";
      console.log(systemDate);
    },
    malist() {
      qrsell({ uid: this.userInfo.id, gid: this.shangjia.id }).then(res => {
        if (res.code == 200) {
          this.downloadData.url = res.data.url;
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });
    }
  },
  created() {
    this.addDate();
    this.namelist();
    this.teamlond();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
// ---------------------
.keyboard {
  z-index: 9999;
}

.van-dialog {
  width: 300px;
  z-index: 9;
}
.tanhes {
  padding: 10px 20px 20px 15px;
  overflow: hidden;
}
.tanh1 {
  line-height: 26px;
  font-size: 14px;
  margin-top: 6px;
  // margin-bottom: 6px;
  padding: 2px 0;
  overflow: hidden;
}
// ---------------------
.bost {
  display: inline-block;
}
.biao_ti {
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 13px;

  // font-weight: bold;
  //   line-height: 46px;
  text-align: center;
  color: #333;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  // overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.dd {
  height: 16px;
}
.mian {
  padding-top: 46px;
  height: 100%;
}
.shijian {
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
}
.tis {
  height: 46px;
  margin-left: 20px;
  line-height: 18px;
  // margin-top: 4px;
  position: relative;
  padding-top: 3px;
}
.heng {
  height: 1px;
  background: #333;
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.heds {
  font-size: 12px;
  //   padding-top: 46px;
  margin: 12px 10px 0 10px;
  background: #fff;
  border-radius: 8px;
  // min-height: 220px;
}
.hedst {
  font-size: 12px;
  //   padding-top: 46px;
  margin-top: 12px;
  // margin: 12px 10px 0 10px;
  background: #fff;
  // border-radius: 8px;
  height: 96%;
  overflow: hidden;
}
.neimian {
  height: 85%;
  overflow-y: auto;
}
.van-col {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 45px;
}
.van-row {
  text-align: center;
  // height: 45px;

  border-bottom: 1px solid #f5f5f5;
}
.neimian .van-col {
  height: 45px;
}
.xiaqu {
  transform: rotate(90deg);
  vertical-align: middle;
}
.imgse {
  background: #fff;
  height: 100%;
  overflow: hidden;
  // border-radius: 15px;
  // margin: 10px 20px;
  padding: 10px 0;
}
.ma {
  text-align: center;
  // display: block;
  margin: auto;
  margin-top: 30%;
}
.yonghu {
  width: 74px;
  float: left;
  text-align: right;
  margin-right: 8px;
}
input {
  padding: 0 6px;
  height: 28px;
  line-height: 28px;
  width: 130px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 0 0.5px #dcdfe6;
}
.chaxun {
  height: 28px;
  margin-left: 4px;
}
.toimg {
  // margin-left: 10px;
  width: 25px;
  border: 2px solid #fff;
}
.myma {
  // display: flex;
  // align-items: center;
  // height: 40px;
}
.logoimg {
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
  
}
.divban {
  float: left;
  width: 50%;
    line-height: 35px;
     border-top: 1px solid #f5f5f5;
}
</style>